import { ChatReport } from "../../../shared/models/ChatReport";
import {
  Box,
  Button,
  Center,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { useEffect } from "react";
import { MarkdownWithCitations } from "../../sources/MarkdownWithCitations";
import markdownClasses from "../../../shared/styles/Markdown.module.css";

interface ReportPageProps {
  report: ChatReport | undefined;
  setReport: (report: ChatReport | undefined) => void;
  newReport?: ChatReport;
  createReport: () => void;
  existingReport?: ChatReport;
  isOutdated: boolean;
}

export default function ReportPage({
  report,
  setReport,
  existingReport,
  createReport,
  isOutdated,
}: ReportPageProps) {
  useEffect(() => {
    if (!existingReport && !report) {
      createReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const regenerateReport = () => {
    setReport(undefined);
    createReport();
  };

  return (
    <>
      {report ? (
        <>
          <Group py={3}>
            <Button
              variant={isOutdated ? "primary" : "secondary"}
              radius="xxs"
              onClick={regenerateReport}
            >
              Regenerate Report
            </Button>
            <Text>
              This report was created at {report.createdAt.toLocaleString()}
            </Text>
            {isOutdated && (
              <Box>
                <Text c="red">
                  This report is outdated. Please regenerate so the latest chat
                  messages will affect report
                </Text>
              </Box>
            )}
          </Group>
          <ScrollArea.Autosize>
            <MarkdownWithCitations
              content={report.text}
              className={markdownClasses["markdown"]}
            />
          </ScrollArea.Autosize>
        </>
      ) : (
        <Center h="100%" w="100%">
          <Stack align="center">
            <Loader />
            <Text>Generating report</Text>
          </Stack>
        </Center>
      )}
    </>
  );
}
