import { useApiPrivateRequest } from "../../../shared/hooks/useApi";

export const useSuggestQuestions = (chatId: string) => {
  const { data: suggestedQuestions, call } = useApiPrivateRequest<string[]>();
  const suggestQuestions = async () => {
    await call(`/chat/${chatId}/suggestQuestions`, "POST", undefined, {});
  };

  return { newSuggestedQuestions: suggestedQuestions, suggestQuestions };
};
