import { Button, Group, Stack, Text, Tooltip } from "@mantine/core";
import CenterLoader from "../../../shared/components/loader/CenterLoader";
import {
  EventName,
  EventParameter,
  EventTitle,
  sendEvent,
} from "../../../shared/utils/UsageAnalytics";

const constantSuggestedQuestions = {
  Summarise:
    "Summarise the information so far in bullet points, using 150 words or less.",
  Continue: "Continue the analysis in the best way you see fit.",
  Critique:
    "Critique the analysis above, suggest potential improvements or gaps, as well as other areas or questions we should be thinking about.",
};

interface SuggestedQuestionsProps {
  suggestedQuestions: string[];
  processInput: (input: string) => void;
}

export function SuggestedQuestions({
  suggestedQuestions,
  processInput,
}: SuggestedQuestionsProps) {
  const processSuggestedQuestionClick = (
    question: string,
    isGenerated: boolean,
  ) => {
    sendEvent(EventName.SuggestedQuestionSelected, {
      [EventParameter.SuggestedQuestionType]: isGenerated
        ? EventTitle.GeneratedSuggestedQuestion
        : EventTitle.DefaultSuggestedQuestion,
      [EventParameter.SuggestedQuestion]: question,
    });
    processInput(question);
  };

  return (
    <Stack w="100%" gap={2}>
      {suggestedQuestions && suggestedQuestions.length > 0 ? (
        <Group w="100%" grow gap={2}>
          {suggestedQuestions.map((question, index) => (
            <Tooltip key={index} label={question} withArrow openDelay={300}>
              <Button
                onClick={() => processSuggestedQuestionClick(question, true)}
                style={{ flex: 1, minWidth: 0 }}
                variant="outline"
                radius="xs"
              >
                <Text truncate="end" size="sm">
                  {question}
                </Text>
              </Button>
            </Tooltip>
          ))}
        </Group>
      ) : (
        <CenterLoader />
      )}
      <Group w="100%" grow gap={2}>
        {Object.entries(constantSuggestedQuestions).map(
          ([key, value], index) => (
            <Button
              key={index}
              onClick={() => processSuggestedQuestionClick(value, false)}
              style={{ flex: 1, minWidth: 0 }}
              variant="outline"
              radius="xs"
            >
              <Text truncate="end" size="sm">
                {key}
              </Text>
            </Button>
          ),
        )}
      </Group>
    </Stack>
  );
}
